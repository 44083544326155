import React from "react";
import { graphql } from "gatsby";
import { Row, Col, Container } from "reactstrap";

import Layout from "../components/Layout";
import Slider from "../components/Slider";
import PostRoll from "../components/PostRoll";

import textStripe from "../img/template/text.svg";

export const IndexPageTemplate = ({ slides, posts, html }) => (
  <Layout>
    <Container fluid>
      <Row>
        <Col sm={12}>
          <Slider slides={slides} />
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col sm={12}>
          <img
            src={textStripe}
            alt="wuxing"
            style={{
              width: "100%",
              padding: "1.5rem 0",
              display: "block",
            }}
          />
        </Col>
      </Row>
    </Container>
    <Container fluid>
      <Row>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "var(--blue)",
          }}
        ></div>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col sm={12}>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </Col>
      </Row>
      <PostRoll posts={posts} />
    </Container>
  </Layout>
);

export default ({ data }) => {
  const slides = data.allMarkdownRemark.edges
    .filter((e) => e.node.frontmatter.type === "slide")
    .map((e) => e.node.frontmatter);

  const posts = data.allMarkdownRemark.edges
    // .filter(e => e.node.fileAbsolutePath.indexOf('/clanky/') > -1 || e.node.fileAbsolutePath.indexOf('/udalosti/') > -1)
    .filter((e) => e.node.fileAbsolutePath.indexOf("/udalosti/") > -1)
    .map((e) => ({
      ...e.node.frontmatter,
      slug: e.node.fields.slug,
      excerpt: e.node.excerpt,
    }))
    .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate));

  return (
    <IndexPageTemplate
      slides={slides}
      posts={posts}
      html={data.markdownRemark.html}
    />
  );
};

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
    }
    allMarkdownRemark {
      edges {
        node {
          fileAbsolutePath
          fields {
            slug
          }
          excerpt(pruneLength: 120)
          frontmatter {
            title
            type
            dateText
            link
            eventDate
            image {
              childImageSharp {
                fluid(maxWidth: 2600, quality: 40) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
