import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Carousel from 'react-flex-carousel';

import css from './slider.module.css';

const Slider = props => (
  <div className={css.slider}>
    <Carousel autoPlayInterval={5000} indicator={false} switcher={false}>
      {props.slides.map((slide, i) => (
        <div key={i}>
          {slide.link ? (
            <a href={slide.link}>
              <Img fluid={slide.image.childImageSharp.fluid} alt={slide.title} />
            </a>
          ) : (
              <Img fluid={slide.image.childImageSharp.fluid} alt={slide.title} />
            )}
        </div>
      ))}
    </Carousel>
  </div>
)

export default Slider;
