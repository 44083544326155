import React from "react";
import { Link } from "gatsby";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText
} from "reactstrap";
import Img from "gatsby-image";

import { formatDate, isMoreDayEvent, formatTime } from "../lib/date";
import "./postroll.css";

export default ({ posts }) => (
  <Row className="postroll">
    {posts.map((p, i) => (
      <Col md={3} xs={6} key={i}>
        <Card>
          {p.image ? (
            <Img
              className="card-img-top"
              fluid={p.image.childImageSharp.fluid}
              alt={p.title}
            />
          ) : (
            ""
          )}
          <CardBody>
            <CardTitle>{p.title}</CardTitle>
            <CardSubtitle>{p.dateText}</CardSubtitle>
            <CardText>{p.excerpt}</CardText>
            <Link to={p.slug} className="stretched-link" />
          </CardBody>
        </Card>
      </Col>
    ))}
  </Row>
);
